import { Component, Element, h, Host, Prop } from '@stencil/core';
import PTZTokens from '@petz/ds-tokens/petz';
import { PTZCardTagConfig } from './types/ptz-card-tag.enums';
import { PTZCardTagTypes } from './types/ptz-card-tag.types';

@Component({
  tag: 'ptz-card-tag',
  styleUrl: 'ptz-card-tag.scss',
  shadow: false
})

export class PTZCardTag {
  /** label da tag */
  @Prop() label: string

  /** Estilo de cor do Banner */
  @Prop() kind?:PTZCardTagTypes.Kind = PTZCardTagConfig.Kind.Default;

  @Element() el: HTMLElement;

  render() {
    const KindPalette = {
      default: {
        bg: PTZTokens.colorSecondaryBrand,
        text: PTZTokens.colorNeutralBlack,
      },
      blackfriday: {
        bg: PTZTokens.colorPrimaryBlackFriday,
        text: PTZTokens.colorNeutralWhite,
      },
    }

    const handleCardTagColor = () => {
      this.el.style.setProperty('--card-tag-color-bg', KindPalette[this.kind].bg);
      this.el.style.setProperty('--card-tag-color-text', KindPalette[this.kind].text);
    }
    handleCardTagColor();

    return (
      <Host>
        <span class='ptz-card-tag'>
          {this.kind === PTZCardTagConfig.Kind.Blackfriday && (
            <ptz-icon name={'fire'} variant={'line'} size="xs" />
          )}
          {this.label}
        </span>
      </Host>
    );
  }
}
