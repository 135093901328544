import { Component, Event, EventEmitter, Host, Listen, Prop, State, h } from '@stencil/core';
import { currentId } from '../../utils/id';
import { PTZBrandConfig } from '../ptz-brand/types/ptz-brand.enums';
import { PTZBrandTypes } from '../ptz-brand/types/ptz-brand.types';
import { PTZDropdownOptionTypes } from '../ptz-dropdown-option/types/ptz-dropdown-option.types';
import { PTZInputConfig } from '../ptz-input/types/ptz-input.enums';
import { PTZInputTypes } from '../ptz-input/types/ptz-input.types';
import { PTZDropdownFieldConfig } from './types/ptz-dropdown-field.enums';
import { PTZDropdownFieldTypes } from './types/ptz-dropdown-field.types';

let index = 0;

@Component({
  tag: 'ptz-dropdown-field',
  styleUrl: 'ptz-dropdown-field.scss',
  shadow: false,
})
export class PTZDropdownField {
  /** Input com autocomplete */
  @Prop() autoComplete: boolean = false;

  /** Define o estilo da marca do Input */
  @Prop() brand?: PTZBrandTypes.Name = PTZBrandConfig.Name.Petz;

  /** Estado do Input */
  @Prop() inputState?: PTZInputTypes.State = PTZInputConfig.State.Resting;

  /** Variante do input */
  @Prop() kind: PTZDropdownFieldTypes.Kind = PTZDropdownFieldConfig.Kind.Boxed;

  /** Preenche label do input */
  @Prop() label: string;

  @State() openDropdown: boolean = false;

  /** Preenche placeholder do input */
  @Prop() placeholder?: string;

  /** Preenche required padrão do Input */
  @Prop() required?: boolean = false;

  /** Preenche label quando input for obrigatório */
  @Prop() requirementLabel?: PTZInputTypes.RequirementLabel;

  /** Tamanho do input */
  @Prop() size: PTZInputTypes.Size = PTZInputConfig.Size.Large;

  /** Mensagem de suporte do Input */
  @Prop() supportLabel?: string;

  /** Preenche value do input */
  @Prop({ mutable: false }) value: string;

  /** Preenche id padrão do Input */
  @Prop() inputId?: string;

  /** Habilitar spellcheck padrão do Input */
  @Prop() spellcheck: boolean = false;

  @Event({
    eventName: 'getValue',
    composed: true,
    cancelable: true,
    bubbles: true,
  })
  getValue: EventEmitter<PTZDropdownOptionTypes.GetValue>;

  @Event({
    eventName: 'filterValues',
    composed: true,
    cancelable: true,
    bubbles: true,
  })
  filterValues: EventEmitter<PTZDropdownOptionTypes.FilterValues>;

  @Listen('optionSelected')
  getOption(event: CustomEvent<PTZDropdownOptionTypes.OptionSelected>) {
    const value = event.detail.value;
    this.value = value;
    this.openDropdown = false;
  }

  onStateChanged() {
    const fieldIcon = document.getElementById(`${this.currentInputId}-dropdown-field-icon`);
    if (this.inputState === 'loading') {
      fieldIcon.classList.add('hide-field-icon');
    } else {
      fieldIcon.classList.remove('hide-field-icon');
    }
  }

  componentDidLoad() {
    this.onStateChanged();
  }

  private currentInputId = currentId(this.inputId);

  private handleOpenDropdown(event: MouseEvent) {
    if (this.inputState === 'disabled' || this.inputState === 'loading') {
      return;
    }

    this.openDropdown = !this.openDropdown;

    const value = (event.target as HTMLInputElement).value;

    this.getValue.emit({ value });
  }

  private handleOnBlur() {
    setTimeout(() => {
      this.openDropdown = false;
    }, 200);
  }

  private handlerArrows(index: number, isArrowDown: boolean, id) {
    const option = document.querySelectorAll(`#${id} ~ #ptz-dropdown-menu ptz-dropdown-option li a`);
    const maxOption = option.length;
    const indexValue = index >= maxOption ? maxOption : index;
    const clearIndex = index <= 0 ? 0 : indexValue;
    const arrowDownValue = clearIndex <= 0 ? clearIndex : clearIndex - 1;
    const prevOption = isArrowDown ? arrowDownValue : clearIndex + 1;

    if (clearIndex >= 0 && clearIndex < maxOption) {
      option[clearIndex].classList.add('ptz-dropdown-option-selected');
      option[prevOption].classList.remove('ptz-dropdown-option-selected');

      if (!this.autoComplete) {
        document.getElementById('ptz-dropdown-menu').classList.remove(`${this.brand}-dropdown-field-menu-open`);
        this.value = (option[clearIndex] as HTMLElement).dataset.value;
      }
    }
  }

  private handleKeyDown(event: KeyboardEvent) {
    switch (event.code) {
      case 'Enter':
        this.openDropdown = !this.openDropdown;
        break;
      case 'ArrowDown':
        this.openDropdown = true;
        this.handlerArrows(index, true, this.currentInputId);
        index++;
        break;
      case 'ArrowUp':
        this.openDropdown = true;
        index--;
        this.handlerArrows(index, false, this.currentInputId);
        break;
      case 'Escape':
        this.handleOnBlur();
        break;
      default:
        return '';
    }
  }

  private handleKeyUp(event: KeyboardEvent) {
    this.openDropdown = true;

    const value = (event.target as HTMLInputElement).value;

    this.filterValues.emit({ currentValue: value });
  }

  render() {
    return (
      <Host>
        <ptz-input
          class={{
            'ptz-dropdown-field': true,
            'ptz-dropdown-field-disabled': this.inputState === 'disabled',
            'ptz-dropdown-field-loading': this.inputState === 'loading',
            'ptz-dropdown-field-readonly': !this.autoComplete,
          }}
          onKeyUp={this.autoComplete ? event => this.handleKeyUp(event) : null}
          onKeyDown={event => this.handleKeyDown(event)}
          onClick={event => this.handleOpenDropdown(event)}
          inputOnBlur={() => this.handleOnBlur()}
          kind={this.kind}
          size={this.size}
          value={this.value}
          brand={this.brand}
          supportLabel={this.supportLabel}
          autocomplete="off"
          requirementLabel={this.requirementLabel}
          required={this.required}
          placeholder={this.placeholder}
          inputId={this.currentInputId}
          label={this.label}
          state={this.inputState}
          spellcheck={this.spellcheck}
          readonly={this.autoComplete ? false : true}
        >
          <a
            id={`${this.currentInputId}-dropdown-field-icon`}
            onClick={() => (this.openDropdown = !this.openDropdown)}
            class={{
              [`${this.brand}-dropdown-field-icon`]: true,
              [`${this.brand}-dropdown-field-icon-${this.kind}-${this.size}-support-label`]: Boolean(this.supportLabel),
              [`${this.brand}-dropdown-field-icon-${this.kind}-${this.size}`]: Boolean(!this.supportLabel),
            }}
          >
            <ptz-icon name={this.openDropdown ? 'petz-chevron-up' : 'petz-chevron-down'} variant="line" size="lg"></ptz-icon>
          </a>
        </ptz-input>
        <ptz-dropdown-menu
          kind={this.kind}
          brand={this.brand}
          id="ptz-dropdown-menu"
          class={{
            [`${this.brand}-dropdown-field-menu`]: true,
            [`${this.brand}-dropdown-field-menu-${this.size}`]: this.label && this.brand !== PTZBrandConfig.Name.Seres,
            [`${this.brand}-dropdown-field-menu-${this.size}-${this.kind}`]: this.label && this.brand === PTZBrandConfig.Name.Seres,
            [`${this.brand}-dropdown-field-menu-open`]: this.openDropdown,
          }}
          tabindex="0"
        >
          <slot />
        </ptz-dropdown-menu>
      </Host>
    );
  }
}
