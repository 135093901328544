ptz-card-tag {
  --card-tag-color-bg: $petz-color-secondary-brand;
  --card-tag-color-text: $petz-color-neutral-black;

  .ptz-card-tag {
    display: flex;
    color: var(--card-tag-color-text);
    justify-content: center;
    align-items: center;
    border-radius: $petz-rounded-2xs;
    min-width: max-content;
    font-weight: $petz-font-weight-bold;
    font-size: $petz-font-size-2xs;
    line-height: $petz-font-lineheight-lg;
    text-align: center;
    background-color: var(--card-tag-color-bg);
    padding: $petz-spacing-3xs $petz-spacing-2xs;
    transform: translate(-50%, 0);
    left: 50%;
    bottom: 0;
    position: absolute;

    i {
      margin-right: 0.375rem;
    }
  }
}
