ptz-dropdown-field {
  position: relative;

  input {
    width: 260px;
    padding-right: 45px;
  }

  .ptz-input,
  .srs-input {
    cursor: pointer;
  }

  .hide-field-icon {
    display: none;
  }

  .ptz-dropdown-field {
    &-readonly {
      input {
        cursor: pointer;
      }
    }

    &-disabled {
      input {
        cursor: not-allowed;
        background-color: $petz-color-neutral-lighter-accent;
        color: $petz-color-neutral-dark;
      }

      a {
        color: $petz-color-neutral-mid;
      }
    }

    &-loading {
      input {
        cursor: wait;
        border: 1px solid $petz-color-neutral-dark;
      }
    }

    &-icon {
      position: absolute;
      cursor: pointer;
      pointer-events: none;
      right: 15px;

      &-boxed,
      &-lined {
        &-sm {
          bottom: 8px;

          &-support-label {
            bottom: 25px;
          }
        }

        &-md {
          bottom: 12px;

          &-support-label {
            bottom: 30px;
          }
        }

        &-lg {
          bottom: 18px;

          &-support-label {
            bottom: 35px;
          }
        }

        &-xl {
          bottom: 29px;

          &-support-label {
            bottom: 47px;
          }
        }
      }

      i {
        display: flex;
      }
    }

    &-menu {
      display: none;
      position: absolute;
      top: 40px;
      left: 0px;

      &-sm {
        top: 63px;
      }

      &-md {
        top: 70px;
      }

      &-lg {
        top: 83px;
      }

      &-xl {
        top: 107px;
      }

      &-open {
        display: block;
      }
    }
  }

  .srs-dropdown-field {
    &-readonly {
      input {
        cursor: pointer;
      }
    }

    &-disabled {
      input {
        cursor: not-allowed;
        background-color: $petz-color-neutral-lighter-accent;
        color: $petz-color-neutral-dark;
      }

      a {
        color: $petz-color-neutral-mid;
      }
    }

    &-loading {
      input {
        cursor: wait;
        border: 1px solid $petz-color-neutral-dark;
      }
    }

    &-icon {
      position: absolute;
      pointer-events: none;
      cursor: pointer;

      &-boxed-sm,
      &-boxed-md,
      &-boxed-lg,
      &-boxed-xl {
        right: 15px;
        bottom: 12px;

        &-support-label {
          right: 15px;
          bottom: 29px;
        }
      }

      &-lined-sm,
      &-lined-md,
      &-lined-xl {
        right: 15px;
        bottom: 12px;

        &-support-label {
          right: 15px;
          bottom: 31px;
        }
      }

      &-lined-lg {
        right: 18px;
        bottom: 17px;

        &-support-label {
          right: 18px;
          bottom: 35px;
        }
      }

      i {
        display: flex;
      }
    }

    &-menu {
      display: none;
      position: absolute;
      top: 40px;
      left: 0px;

      &-sm,
      &-md,
      &-xl {
        &-boxed {
          top: 68px;
        }

        &-lined {
          top: 37px;
        }
      }

      &-lg {
        &-boxed {
          top: 68px;
        }

        &-lined {
          top: 38px;
        }
      }

      &-open {
        display: block;
      }
    }
  }
}
