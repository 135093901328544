import { Component, h, Host, Prop, State, Event, EventEmitter } from '@stencil/core';
import { PTZCardImageTypes } from './types/ptz-card-image.types';
import { PTZCardImageConfig } from './types/ptz-card-image.enums';

@Component({
  tag: 'ptz-card-image',
  styleUrl: 'ptz-card-image.scss',
  shadow: false
})

export class PTZCardImage {
  /** Texto alternativo para a imagem */
  @Prop() alt: string

  /** Url da imagem */
  @Prop() url: string

  /** Adiciona lazyload na imagem */
  @Prop() lazyload?: boolean = false

  /** Habilita a imagem reserva quando url da imagem está indisponível */
  @Prop() fallbackImageEnabled?: boolean = false

  /** Indica a orientação do card */
  @Prop() size?: PTZCardImageTypes.Size = PTZCardImageConfig.Size.Large;

  /** Largura da imagem */
  @Prop() width?: number;

  /** Altura da imagem */
  @Prop() height?: number;

  /** Estado que indica se será mostrada a imagem reserva */
  @State() showfallbackImage: boolean = false

  /** Evento disparado no onError da tag img */
  @Event({
    eventName: 'imageError',
    composed: true,
  }) imageError: EventEmitter;

  private handleImageError() {
    this.showfallbackImage = this.fallbackImageEnabled
    this.imageError.emit()
  }

  render() {
    const imgAttributes = {
      class: 'ptz-card-image',
      src: this.url,
      alt: this.alt,
      width: this.width,
      height: this.height,
    };

    const iconSize = this.size === PTZCardImageConfig.Size.Large ? '2xl' : 'xs';

    if (this.lazyload) {
      imgAttributes['loading'] = 'lazy';
    }

    return (
      <Host class={!this.showfallbackImage ? 'ptz-card-image-success' : ''}>
        {this.showfallbackImage ? (
          <div class={`ptz-card-image-fallback ptz-card-image-fallback-${this.size}`} aria-label={this.alt}>
            <ptz-icon name='image' brand='ptz' color='neutral-dark' size={iconSize} variant='line' />
          </div>
        ) : (
          <img {...imgAttributes} onError={() => this.handleImageError()} />
        )}
      </Host>
    );
  }
}
