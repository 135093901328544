import { EnumToArray } from '../../../utils/enumToArray';

enum Kind {
  Offer = 'offer',
  Product = 'product',
  Showcase = 'showcase',
  Subscription = 'subscription',
}

enum Orientation {
  Vertical = 'vertical',
  Horizontal = 'horizontal',
}

enum Size {
  Medium = 'md',
  Large = 'lg',
  ExtraLarge = 'xlg',
}

enum State {
  Normal = 'normal',
  Loading = 'loading',
  Disabled = 'disabled',
  Actived = 'actived',
}

export const PTZCardConfigList = {
  Kind: EnumToArray([Kind]),
  Orientation: EnumToArray([Orientation]),
  Size: EnumToArray([Size]),
  State: EnumToArray([State]),
};

export const PTZCardConfig = {
  Kind,
  Orientation,
  Size,
  State,
};
