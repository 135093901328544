import { Component, h, Host, Prop } from '@stencil/core';
import { PTZCardTypes } from '../ptz-card/types/ptz-card.types';
import { PTZCardConfig } from '../ptz-card/types/ptz-card.enums';

@Component({
  tag: 'ptz-card-subscription-section',
  styleUrl: 'ptz-card-subscription-section.scss',
  shadow: false
})

export class PTZCardSubscriptionSection {
  /** Desconto do Card para assinantes */
  @Prop() subscriptionDiscount?: string | number = 0;

  /** Ícone de adicionar à sacola */
  @Prop() showBuyIcon?: boolean;

  /** Preço do Card para assinantes */
  @Prop() subscriptionPrice?: string | number;

  /** Texto de assinantes do Card */
  @Prop() subscriptionText?: string;

  /** Tipo do Card */
  @Prop() kind?: PTZCardTypes.Kind = PTZCardConfig.Kind.Product;

  private shouldShowSubscriptionDiscount = () => {
    if (this.subscriptionDiscount == null) return false;

    if (typeof this.subscriptionDiscount == "string") return this.subscriptionDiscount.length > 0;

    return this.subscriptionDiscount > 0
  }

  render() {
    return (
      <Host>
        <div class={`ptz-card-subscription`}>
          {this.shouldShowSubscriptionDiscount() && this.kind === PTZCardConfig.Kind.Product ? <span class="ptz-card-subscription-discount">
            {this.subscriptionDiscount}
          </span> : ''}
          <p class="ptz-card-subscription-price">
            <ptz-icon name='petz-subscription' size="sm" color='primary-brand' variant='line' />
            {this.subscriptionPrice}
          </p>
          <span class="ptz-card-subscription-text">
            {this.subscriptionText}
            {this.shouldShowSubscriptionDiscount() && this.kind === PTZCardConfig.Kind.Showcase ?
              <ptz-badge
                color="primary-brand"
                size="xs"
                variant="solid"
                brand="ptz"
              >
                {this.subscriptionDiscount}
              </ptz-badge>
            : ''}
          </span>
          {this.showBuyIcon
            ? <button class="ptz-card-subscription-plus" aria-role="subscription-plus">
              <ptz-icon name='plus' size="md" color='neutral-white' variant='line' />
            </button>
            : null
          }
        </div>
      </Host>
    );
  }
}
