ptz-card-image {
  display: flex;
  justify-content: center;
  position: relative;
  margin-bottom: $petz-spacing-2xs;

  &.ptz-card-image-success {
    height: 6.875rem;
  }

  .ptz-card-image {
    display: block;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    object-fit: cover;
    width: auto;
    height: auto;
    max-height: $petz-spacing-xl * 2.5;
    font-size: $petz-font-size-3xs;
    line-height: $petz-font-lineheight-lg;

    &-fallback {
      border: none;
      display: flex;
      align-items: center;
      gap: $petz-spacing-2xs;
      justify-content: center;
      padding: $petz-spacing-sm 0;
      border-radius: $petz-rounded-max;
      background-color: $petz-color-neutral-lighter-accent;

      &-lg {
        min-width: $petz-spacing-4xl;
        max-height: $petz-spacing-4xl;
      }

      &-sm {
        min-width: $petz-spacing-2xl;
        max-height: $petz-spacing-2xl;
      }
    }
  }
}
