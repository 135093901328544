import { EnumToArray } from '../../../utils/enumToArray';

enum Size {
  Large = 'lg',
  Small = 'sm',
}

export const PTZCardImageConfigList = {
  Size: EnumToArray([Size]),
};

export const PTZCardImageConfig = {
  Size,
};
