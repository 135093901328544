import { Component, Event, EventEmitter, h, Host, Listen, Method, Prop } from '@stencil/core';
import { PTZTextFieldTypes } from './types/ptz-text-field.types';
import { PTZTextFieldConfig } from './types/ptz-text-field.enums';
import { PTZIconTypes } from '../ptz-icon/types/ptz-icon.types';
import { PTZInputTypes } from '../ptz-input/types/ptz-input.types';
import { PTZInputConfig } from '../ptz-input/types/ptz-input.enums';
import { PTZPasswordFieldTypes } from '../ptz-password-field/types/ptz-password-field.types';
import { PTZPasswordFieldConfig } from '../ptz-password-field/types/ptz-password-field.enums';
import { PTZBrandTypes } from '../ptz-brand/types/ptz-brand.types';
import { PTZBrandConfig } from '../ptz-brand/types/ptz-brand.enums';
import { currentId } from '../../utils/id';
import { PTZDropdownOptionTypes } from '../ptz-dropdown-option/types/ptz-dropdown-option.types';
import { PtzDropdownOptionCustomEvent } from '../../components';

@Component({
  tag: 'ptz-text-field',
  styleUrl: 'ptz-text-field.scss',
  shadow: false,

})

export class PTZTextField {
  /** INPUT **/
  /** Controle de input */
  @Prop() control?: PTZTextFieldTypes.Control = PTZTextFieldConfig.Control.Normal

  /** Preenche label */
  @Prop() label?: string

  /** Estado de renderização */
  @Prop() skeleton: boolean

  /** Preenche label quando input for obrigatório */
  @Prop() requirementLabel?: PTZInputTypes.RequirementLabel

  /** Preenche required padrão do Input */
  @Prop() required?: boolean = false

  /** Preenche placeholder do input */
  @Prop() placeholder?: string

  /** Atributo onblur padrão do input */
  @Prop() inputOnBlur?: (event: FocusEvent) => void

  /** Variante do input */
  @Prop() kind?: PTZInputTypes.Kind = PTZInputConfig.Kind.Boxed

  /** Tamanho do input */
  @Prop() size?: PTZInputTypes.Size = PTZInputConfig.Size.Mid

  /** Estado do input */
  @Prop() state?: PTZInputTypes.State = PTZInputConfig.State.Resting

  /** Preenche id padrão do input */
  @Prop() inputId?: string

  /** Preenche type padrão do input */
  @Prop() type?: string = 'text'

  /** Atributo name padrão do input */
  @Prop() name: string

  /** Atributo value padrão do input */
  @Prop({mutable: true}) value: string

  /** Nome do ícone no input */
  @Prop() iconName?: PTZIconTypes.Name

  /** Variante do ícone no input */
  @Prop() iconVariant?: PTZIconTypes.Variant

  /** Mensagem de suporte do Input */
  @Prop() supportLabel?: string

  /** Variante do ícone no Input */
  @Prop() iconAlign?: PTZIconTypes.Align = PTZInputConfig.IconAlign.Left

  /** Define o estilo da marca do Input */
  @Prop() brand?: PTZBrandTypes.Name = PTZBrandConfig.Name.Petz

  /** Define o estilo da marca do Input */
  @Prop() iconInformation?: boolean = false

  /** Botão para limpar o Input */
  @Prop() buttonClear?: boolean = false

  /** readonly padrão do Input */
  @Prop() readonly?: boolean

  /** title padrão do Input */
  @Prop() inputTitle?: string

  /** autocomplete padrão do Input */
  @Prop() autocomplete?: PTZInputTypes.AutoComplete = PTZInputConfig.AutoComplete.Off

  /** maxLength padrão do Input */
  @Prop() maxLength?: number

  /** PASSWORD **/
  /** Tipo para senha no input */
  @Prop() inputTypePassword: PTZPasswordFieldTypes.Type = PTZPasswordFieldConfig.Type.Simple

  /** Estado para senha no input */
  @Prop() inputStatePassword: PTZPasswordFieldTypes.State

  /** Habilitar spellcheck padrão do Input */
  @Prop() spellcheck: boolean = false


    /** Preenche id para rules do input de senha */
  @Prop() inputRulesId?: string

  /** NUMBER **/
  /** Define valor máximo no input */
  @Prop() inputMaxNumber: number

  /** Define valor mínimo no input */
  @Prop() inputMinNumber: number

  /** DROPDOWN **/
  /** Define se o dropdown vai ter autocomplete */
  @Prop() inputAutoCompleteDropdown: boolean = false

  /** Descrição das regras de senha */
  @Prop() descriptionRules: string

  @Listen('input')
  onReactChange(e: Event){
    const value = (e.target as unknown as HTMLInputElement).value
    if (value.length == 0) {
      this.value = null
      this.getValue.emit({value})
    }
  }

  @Listen('optionSelected')
  onOptionSelected(e: PtzDropdownOptionCustomEvent<PTZDropdownOptionTypes.OptionSelected>) {
    this.value = e.detail.value
  }

  @Event({
    eventName: 'getValue',
    composed: true,
    cancelable: true,
    bubbles: true,
  })
  getValue: EventEmitter<PTZDropdownOptionTypes.GetValue>;

  private currentRulesId = currentId(this.inputRulesId)

  @Method()
  async setRules(obj: { rule: string, passed: boolean }[]) {
    const textFieldRules = document.getElementById(this.currentRulesId)
    textFieldRules.innerHTML = ''

    if (!!this.descriptionRules) {
      textFieldRules.innerHTML += `<span class="ptz-password-field-description">${this.descriptionRules}</span>`;
    }

    const ptzIconStatusValid = `<ptz-icon name='check-solid' variant='solid' color='sup-green-dark' size='md'></ptz-icon>`;
    const ptzIconStatusInvalid = `<ptz-icon name='petz-cancel' variant='line' color='neutral-mid' size='md'></ptz-icon>`;

    obj.forEach(item => {
      if (textFieldRules) {
        const ptzIcon = item.passed ? ptzIconStatusValid : ptzIconStatusInvalid;
        textFieldRules.innerHTML += `<li>${ptzIcon}<span>${item.rule}</span></li>`;
      }
    })
  }

  render() {
    return (
      <Host>
        {this.control === PTZTextFieldConfig.Control.Normal ?
          <ptz-input
            brand={this.brand}
            skeleton={this.skeleton}
            requirementLabel={this.requirementLabel}
            required={this.required}
            label={this.label}
            value={this.value}
            inputId={this.inputId}
            iconName={this.iconName}
            iconVariant={this.iconVariant}
            iconAlign={this.iconAlign}
            iconInformation={this.iconInformation}
            buttonClear={this.buttonClear}
            placeholder={this.placeholder}
            kind={this.kind}
            state={this.state}
            size={this.size}
            supportLabel={this.supportLabel}
            autocomplete={this.autocomplete}
            maxLength={this.maxLength}
            spellcheck={this.spellcheck}
          ><slot /></ptz-input>
          : ''
        }
        {this.control === PTZTextFieldConfig.Control.Password ?
          <ptz-password-field
            brand={this.brand}
            requirementLabel={this.requirementLabel}
            rulesId={this.currentRulesId}
            required={this.required}
            label={this.label}
            value={this.value}
            type={this.inputTypePassword}
            state={this.inputStatePassword}
            inputState={this.state}
            supportLabel={this.supportLabel}
            kind={this.kind}
            size={this.size}
            placeholder={this.placeholder}
          ></ptz-password-field>
          : ''
        }
        {this.control === PTZTextFieldConfig.Control.Number ?
          <ptz-number-field
            brand={this.brand}
            requirementLabel={this.requirementLabel}
            required={this.required}
            label={this.label}
            value={this.value}
            maxNumber={this.inputMaxNumber}
            minNumber={this.inputMinNumber}
            inputState={this.state}
            supportLabel={this.supportLabel}
            kind={this.kind}
            size={this.size}
          ></ptz-number-field>
          : ''
        }
        {this.control === PTZTextFieldConfig.Control.Dropdown ?
          <ptz-dropdown-field
            brand={this.brand}
            label={this.label}
            value={this.value}
            requirementLabel={this.requirementLabel}
            required={this.required}
            placeholder={this.placeholder}
            inputState={this.state}
            supportLabel={this.supportLabel}
            kind={this.kind}
            size={this.size}
            autoComplete={this.inputAutoCompleteDropdown}
          ><slot /></ptz-dropdown-field> : ''
        }
      </Host>
    );
  }
}
