ptz-password-field {
  display: inline-block;

  .ptz-password-field {
    width: 100%;

    input {
      width: 260px;
      padding-right: 45px;
    }

    &-icon {
      position: absolute;
      cursor: pointer;
      right: 15px;

      &-boxed,
      &-lined {
        &-sm {
          bottom: 7px;

          &-support-label {
            bottom: 25px;
          }
        }

        &-md {
          bottom: 12px;

          &-support-label {
            bottom: 30px;
          }
        }

        &-lg {
          bottom: 18px;

          &-support-label {
            bottom: 35px;
          }
        }

        &-xl {
          bottom: 29px;

          &-support-label {
            bottom: 47px;
          }
        }
      }

      i {
        display: flex;
      }
    }

    &-progress {
      appearance: none;
      background-color: $petz-color-neutral-lighter-accent;
      border-radius: $petz-rounded-2xl;
      height: 5px;

      &-boxed {
        width: 31%;
      }

      &-lined {
        width: 100%;
      }

      &-error:first-child {
        background-color: $petz-color-sup-red-mid;
      }

      &-warning:first-child,
      &-warning:nth-child(2) {
        background-color: $petz-color-sup-yellow-mid;
      }

      &-success {
        background-color: $petz-color-sup-green-mid;
      }

      &-div {
        display: flex;
        justify-content: space-between;
        margin-top: 5px;

        meter:-webkit-meter-bar {
          appearance: none;
        }

        &-lined {
          margin: 0;
        }
      }
    }

    &-rules {
      margin: 0;
      padding: 0;
      margin-top: 5px;
      width: 320px;
      display: flex;
      flex-wrap: wrap;

      li {
        width: 50%;
        color: $petz-color-neutral-dark;
        font-weight: $petz-font-weight-regular;
        font-size: $petz-font-size-3xs;
        list-style: none;
        display: flex;
        align-items: center;

        span {
          margin-left: $petz-spacing-3xs;
        }

        ptz-icon {
          i {
            display: flex;
          }
        }
      }
    }

    &-description {
      width: 100%;
      font-size: $petz-font-size-3xs;
      font-weight: $petz-font-weight-bold;
      margin: $petz-spacing-3xs 0 $petz-spacing-2xs;
    }
  }

  .srs-password-field {
    &-icon {
      position: absolute;
      cursor: pointer;

      &-boxed-sm,
      &-boxed-md,
      &-boxed-lg,
      &-boxed-xl {
        right: 15px;
        bottom: 14px;

        &-support-label {
          right: 15px;
          bottom: 31px;
        }
      }

      &-lined-sm,
      &-lined-md,
      &-lined-xl {
        right: 15px;
        bottom: 13px;

        &-support-label {
          right: 15px;
          bottom: 30px;
        }
      }

      &-lined-lg {
        right: 18px;
        bottom: 17px;

        &-support-label {
          right: 18px;
          bottom: 35px;
        }
      }

      i {
        display: flex;
      }
    }
  }
}
