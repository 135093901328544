import { Component, h, Host, Prop } from '@stencil/core';
import { PTZCardTypes } from '../ptz-card/types/ptz-card.types';
import { PTZCardConfig } from '../ptz-card/types/ptz-card.enums';

@Component({
  tag: 'ptz-card-price-section',
  styleUrl: 'ptz-card-price-section.scss',
  shadow: false
})

export class PTZCardPriceSection {
  /** Valor da parcela do Card */
  @Prop() installments?: string;

  /** Preço riscado do Card */
  @Prop() olderPrice?: string | number;

  /** Texto de complemento para o preço antigo */
  @Prop() priceComplementText?: string;

  /** Preço do Card */
  @Prop() price?: string | number;

  /** Tipo de card */
  @Prop() kind?: PTZCardTypes.Kind;

  render() {
    return (
      <Host>
        {this.kind === PTZCardConfig.Kind.Showcase ?
          <p class={`ptz-card-price ptz-card-price-${this.kind}`}>
            <span class={`ptz-card-price-older ptz-card-price-${this.kind}-older`}>
              {this.priceComplementText}
              <span class={`ptz-card-price-older-line-through`}>{this.olderPrice}</span>
            </span>
            {this.price}
            {this.installments
              ? <span class={`ptz-card-price-installments ptz-card-price-${this.kind}-installments`}>
                {this.installments}
              </span>
              : null
            }
          </p>
        :
          <p class="ptz-card-price">
            {this.price}
            <span class="ptz-card-price-older">
              {this.olderPrice}
            </span>
          {this.installments
            ? <span class="ptz-card-price-installments">
              {this.installments}
            </span>
            : null
          }
          </p>
        }
      </Host>
    );
  }
}
