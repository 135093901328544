ptz-card-subscription-section {
  .ptz-card-subscription {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: $petz-spacing-xs;
    font-weight: $petz-font-weight-regular;
    font-size: $petz-font-size-xs;
    line-height: $petz-font-lineheight-xs;

    &-discount, &-price {
      display: flex;
      align-items: flex-end;
      font-weight: $petz-font-weight-bold;
      height: $petz-spacing-sm;
    }

    &-discount {
      background: $petz-color-primary-brand;
      border-radius: $petz-rounded-max;
      color: $petz-color-neutral-white;
      font-size: $petz-font-size-3xs;
      margin-top: 0;
      padding: $petz-spacing-4xs $petz-spacing-2xs;
      width: fit-content;
    }

    &-price {
      margin: 0;
      font-size: $petz-font-size-xs;
      color: $petz-color-primary-brand;

      i {
        margin: $petz-spacing-3xs $petz-spacing-3xs 0 0;
      }
    }

    &-text {
      margin: 0;
      display: flex;
      font-size: $petz-font-size-3xs;
      color: $petz-color-primary-brand;
      line-height: $petz-font-lineheight-xs;
      align-items: flex-end;

      ptz-badge {
        padding-left: $petz-spacing-3xs;
      }
    }

    &-plus {
      width: $petz-spacing-xl;
      height: $petz-spacing-xl;
      border-radius: 50%;
      display: flex;
      align-items: center;
      position: absolute;
      justify-content: center;
      right: $petz-spacing-sm;
      bottom: $petz-spacing-sm;
      border: $petz-border-none;
      background: $petz-color-neutral-darker-accent;

      i {
        margin-top: $petz-spacing-4xs;
      }
    }
  }
}
