import { EnumToArray } from '../../../utils/enumToArray';
import { PTZBrandConfig, PTZBrandConfigList } from '../../ptz-brand/types/ptz-brand.enums';

enum Kind {
  Boxed = 'boxed',
  Lined = 'lined',
}

enum RequirementLabel {
  Required = 'obrigatório',
  Optional = 'opcional',
}

enum Size {
  Sm = 'sm',
  Mid = 'md',
  Large = 'lg',
  XLarge = 'xl',
}

enum State {
  Error = 'error',
  Warning = 'warning',
  Success = 'success',
}

enum Type {
  Simple = 'simple',
  Bar = 'bar',
}

export const PTZPasswordFieldConfigList = {
  Brand: PTZBrandConfigList.Name,
  Kind: EnumToArray([Kind]),
  RequirementLabel: EnumToArray([RequirementLabel]),
  Size: EnumToArray([Size]),
  State: EnumToArray([State]),
  Type: EnumToArray([Type]),
};

export const PTZPasswordFieldConfig = {
  Brand: PTZBrandConfig.Name,
  Kind,
  RequirementLabel,
  Size,
  State,
  Type,
};
