ptz-card {
  .ptz-card {
    padding: $petz-spacing-sm;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    &-heart-icon-button {
      all: unset;
    }

    &-outlined {
      border: $petz-border-sm solid $petz-color-neutral-light;
      border-radius: $petz-rounded-xs;
    }

    &-portrayal {
      display: flex;
      flex-direction: column;
      align-self: center;
    }

    &-offer {
      &-image {
        ptz-card-image {
          margin: 0 auto;
          position: relative;
        }
      }

      &-loading {
        ptz-button .ptz-btn .ptz-btn-loader {
          border-bottom-color: $petz-color-neutral-black;
        }
      }

      &-actived {
        span strong {
          color: $petz-color-sup-green-darker-accent;
        }

        ptz-icon i {
          color: $petz-color-sup-green-darker-accent;
        }
      }
    }

    &-content {
      display: flex;
      flex-direction: column;
      align-items: center;

      &-wrapper {
        display: contents;
      }
    }

    &-heart-icon {
      right: 1.125rem;
      position: absolute;
      top: $petz-spacing-sm;
    }

    &-product-variations-text {
      text-align: center;
      border-radius: 6.25rem;
      margin: $petz-spacing-xs auto;
      font-weight: $petz-font-weight-bold;
      font-size: $petz-font-size-3xs;
      line-height: $petz-font-lineheight-lg;
      color: $petz-color-neutral-darker-accent;
      padding: $petz-spacing-3xs $petz-spacing-2xs;
      background: $petz-color-neutral-lighter-accent;
    }

    &-label,
    &-support-label {
      text-align: center;
    }

    &-label-left {
      text-align: left;
    }

    &-label,
    &-label-left,
    &-support-label {
      font-weight: $petz-font-weight-regular;
    }

    &-label,
    &-label-left {
      font-size: $petz-font-size-2xs;
      color: $petz-color-neutral-black;
      max-height: $petz-spacing-2xl;
      overflow: hidden;
    }

    &-support-label {
      display: inline-flex;
      align-items: center;
      font-size: $petz-font-size-3xs;
      color: $petz-color-neutral-dark;

      i {
        display: flex;
        margin-right: 0.313rem;
      }
    }

    &-product,
    &-subscription {
      min-width: 12.5rem;
    }

    &-product,
    &-showcase,
    &-subscription {
      .ptz-card-content {
        align-items: flex-start;
        height: 100%;
        width: 100%;
      }

      .ptz-card-product-variations-text {
        margin: 0 auto;
      }
    }

    &-price-older:not(.ptz-card-price-showcase-older) {
        text-decoration: line-through;
    }

    &-showcase {
      padding: 0;
      cursor: pointer;
      box-sizing: border-box;

      &-animated {
        .ptz-card-label-left {
          opacity: 1;
          transition: opacity 300ms ease-out;
        }

        ptz-card-badges-carousel, .ptz-selector-read-only .ptz-selector {
          opacity: 0;
          margin-bottom: 0;
          transition: opacity 300ms ease-out;
        }

        ptz-card-badges-carousel {
          height: 0;
        }

        ptz-selector.ptz-selector-read-only {
          height: 0;
        }

        &:hover {
          .ptz-card-label-left {
            height: 0;
            margin: 0;
            opacity: 0;
            min-height: 0;
          }

          ptz-selector.ptz-selector-read-only {
            height: initial;
          }

          ptz-card-badges-carousel {
            opacity: 1;
            height: initial;
            margin-bottom: 0.375rem;
          }

          .ptz-selector-read-only .ptz-selector {
            opacity: 1;
          }

          .ptz-selector-read-only {
            margin-bottom: 1.375rem;
          }
        }
      }

      .ptz-card-button {
        z-index: 2;
        position: absolute;
        right: $petz-spacing-sm;
        bottom: $petz-spacing-sm;
      }

      .ptz-card-heart-icon {
        z-index: 4;
      }

      .ptz-card-label-left {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        line-height: $petz-font-lineheight-lg;
        min-height: 2.375rem;
        margin-top: 0;
        margin-bottom: $petz-spacing-sm;
      }

      .ptz-card-portrayal {
        width: 100%;
        position: relative;
        justify-content: center;
        box-sizing: border-box;
        padding: $petz-spacing-sm 0;
        border-radius: $petz-spacing-2xs;
        border: $petz-border-sm solid $petz-color-neutral-light;

        .ptz-card-tag {
          z-index: 2;
          left: 0;
          bottom: auto;
          top: $petz-spacing-sm;
          transform: translate(0, 0);
          border-bottom-left-radius: 0;
          border-top-left-radius: 0;
          padding: $petz-spacing-2xs $petz-spacing-xs;
        }
      }

      .ptz-card-subscription-price {
        flex-direction: row-reverse;
        justify-content: flex-end;

        ptz-icon {
          margin-left: $petz-spacing-3xs;
        }
      }

      ptz-card-price-section {
        min-height: auto;
      }

      &-vertical {
        ptz-card-image {
          height: 100%;
          align-items: center;

          .ptz-card-image {
            z-index: 1;
          }
        }

        .ptz-card-portrayal {
          &-lg {
            height: 18.5rem;
          }

          &-md {
            height: 11.5rem;
          }
        }

        &-lg {
          width: 18.5rem;

          ptz-card-image .ptz-card-image {
            width: 14.68rem;
            height: auto;
            max-height: 17rem;
          }
        }

        &-md {
          width: 11.5rem;

          ptz-card-image .ptz-card-image {
            width: 10rem;
            height: auto;
            max-height: 11rem;
          }
        }
      }
    }

    &-product {
      box-sizing: border-box;

      &-vertical {
        min-height: 20.25rem;

        .ptz-card-label-left {
          color: $petz-color-neutral-black;
          line-height: $petz-font-lineheight-lg;
          height: 2.2rem;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          max-height: 2.2rem;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }

        .ptz-card-portrayal {
          min-height: 9.125rem;
        }

        &-md {
          width: 12.5rem;
        }

        &-lg {
          width: 14.813rem;
        }

        &-xlg {
          width: 18.75rem;
        }
      }

      &-horizontal {
        flex-direction: row;
        height: fit-content;
        padding-top: 0;

        ptz-card-image {
          height: 5.625rem;
        }

        .ptz-card-heart-icon {
          top: 4.5rem;
          right: $petz-spacing-md;
          position: absolute;
        }

        ptz-card-subscription-section {
          max-height: 2.313rem;

          .ptz-card-subscription-deprecated {
            display: grid;
            grid-template: 'price discount' 1.156rem / auto 1fr;

            .ptz-card-subscription {
              &-price {
                grid-area: price;
                justify-content: flex-end;

                i {
                  margin-left: 0;
                  margin-right: $petz-spacing-3xs;
                }
              }

              &-discount {
                grid-area: discount;
                margin-top: 0;
                margin-left: $petz-spacing-2xs;
              }
            }

            &.without-price {
              grid-template: 'discount price' 1.35rem / auto $petz-spacing-2xs;
              margin-top: $petz-spacing-3xs;

              .ptz-card-subscription-discount {
                margin-left: 0;
              }
            }
          }
        }

        .ptz-card-label-left {
          height: $petz-spacing-lg;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          max-height: $petz-spacing-lg;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }

        &-md {
          height: 9.875rem;
          width: 20.438rem;
          padding: $petz-spacing-xs;

          .ptz-card-portrayal {
            margin-right: $petz-spacing-2xs;
          }

          .ptz-card-label,
          .ptz-card-label-left {
            margin: 0 0 $petz-spacing-sm;
          }

          .ptz-card-heart-icon {
            top: $petz-spacing-lg * 2;
            right: $petz-spacing-sm;
          }
        }

        &-lg {
          height: 10.375rem;
          width: 23.438rem;
          padding: $petz-spacing-sm $petz-spacing-md;

          .ptz-card-portrayal {
            margin-right: $petz-spacing-sm;
          }

          .ptz-card-label,
          .ptz-card-label-left {
            margin: 0 0 $petz-spacing-xs;
          }

          ptz-card-subscription-section .ptz-card-subscription.without-price {
            margin-top: $petz-spacing-2xs;
          }
        }

        &-fill {
          height: inherit;
          padding: 0;

          .ptz-card-subscription {
            max-height: 3.563rem;
          }

          .ptz-card-portrayal {
            padding-right: $petz-spacing-xs;
            width: 35%;
          }
        }
      }
    }
  }
}

@media screen and (min-width: $petz-breakpoint-md) {
  ptz-card {
    .ptz-card {
      &-showcase {
        &:not(.ptz-card-showcase-animated) {
          .ptz-selector-read-only {
            padding-bottom: $petz-spacing-sm;
          }
        }

        .ptz-card-content {
          padding-top: $petz-spacing-sm;
        }
      }
    }
  }
}

@media screen and (max-width: $petz-breakpoint-md) {
  ptz-card {
    .ptz-card {
      &-unavailable-badge {
        &-md {
          margin-bottom: $petz-spacing-xs;
        }

        &-lg {
          margin-bottom: 10px;
        }

        span {
          line-height: $petz-font-lineheight-lg;
        }
      }

      &-showcase {
        .ptz-card-content {
          & > .ptz-card-label-left:first-child {
            margin-top: $petz-spacing-sm;
          }
        }

        .ptz-card-label-left {
          min-height: 56px;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          text-overflow: ellipsis;
          max-height: $petz-spacing-3xl;
        }

        .ptz-selector-read-only {
          padding-top: $petz-spacing-sm;
          margin-bottom: $petz-spacing-2xs;
        }

        .ptz-card-unavailable-badge {
          padding-top: $petz-spacing-sm;
        }

        &.ptz-card-showcase-with-space {
          &.ptz-card-showcase-vertical-lg {
            .ptz-card-content {
              & > .ptz-card-label-left:first-child {
                margin-top: $petz-spacing-3xl;
              }
            }
          }

          &.ptz-card-showcase-vertical-md {
            .ptz-card-content {
              & > .ptz-card-label-left:first-child {
                margin-top: $petz-spacing-2xl;
              }
            }
          }

          .ptz-card-label-left {
            margin-bottom: $petz-spacing-2xs;
          }
        }

        &-vertical {
          .ptz-card-portrayal {
            .ptz-card-tag {
              top: $petz-spacing-2xs;
              padding: $petz-spacing-3xs $petz-spacing-2xs;
              border-radius: 0rem $petz-rounded-xs $petz-rounded-xs 0rem;
            }

            &-lg {
              height: 12.5rem;
            }

            &-md {
              height: 9.5rem;
            }
          }

          .ptz-card-heart-icon {
            top: 10px;
            right: 10px;
          }

          &-lg {
            width: 12.5rem;

            ptz-card-image .ptz-card-image {
              width: 160px;
              height: auto;
            }
          }

          &-md {
            width: 9.5rem;
            min-width: 9.5rem;

            ptz-card-subscription-section .ptz-card-subscription {
              min-width: 9.5rem;
            }

            ptz-card-image .ptz-card-image {
              width: 8rem;
              height: auto;
            }

            ptz-selector .ptz-selector {
              padding: $petz-spacing-3xs $petz-spacing-2xs;
            }
          }
        }
      }
    }
  }
}
