import { Component, h, Host, Prop } from '@stencil/core';
import { PTZNumberFieldTypes } from './types/ptz-number-field.types';
import { PTZNumberFieldConfig } from './types/ptz-number-field.enums';
import { PTZBrandTypes } from '../ptz-brand/types/ptz-brand.types';
import { PTZBrandConfig } from '../ptz-brand/types/ptz-brand.enums';
import { currentId } from '../../utils/id';

@Component({
  tag: 'ptz-number-field',
  styleUrl: 'ptz-number-field.scss',
  shadow: false
})

export class PTZNumberField {
  /** Define o estilo da marca do Input */
  @Prop() brand?: PTZBrandTypes.Name = PTZBrandConfig.Name.Petz

  /** Define o valor do Input do tipo número */
  @Prop({ mutable: true}) numValue: number = 0

  /** Define o valor do Input */
  @Prop({ mutable: true}) value: string = '0'

  /** Define o estilo da marca do Input */
  @Prop() iconInformation?: boolean = false

  /** Estado do Input */
  @Prop() inputState?: PTZNumberFieldTypes.State = PTZNumberFieldConfig.State.Resting

  /** Variante do NumberField */
  @Prop() kind?: PTZNumberFieldTypes.Kind = PTZNumberFieldConfig.Kind.Boxed

  /** Preenche label do Input */
  @Prop() label?: string

  /** Define valor máximo */
  @Prop() maxNumber: number

  /** Define valor mínimo */
  @Prop() minNumber: number

  /** Preenche required padrão do Input */
  @Prop() required?: boolean = false

  /** Preenche label quando input for obrigatório do Input */
  @Prop() requirementLabel?: PTZNumberFieldTypes.RequirementLabel

  /** Tamanho do NumberField */
  @Prop() size?: PTZNumberFieldTypes.Size = PTZNumberFieldConfig.Size.Mid

  /** Mensagem de suporte do Input */
  @Prop() supportLabel?: string

  /** Preenche id padrão do Input */
  @Prop() inputId?: string

  private currentInputId = currentId(this.inputId)

  componentWillLoad() {
    this.numValue = !Number.isNaN(+this.value) ? this.numValue = +this.value : this.numValue
  }

  private emitValue(id: string) {
    const inputElement = document.getElementById(id) as HTMLInputElement;
    inputElement.value = this.numValue.toString();
    const inputEvent = new Event('input', { bubbles: true });
    inputElement.dispatchEvent(inputEvent);
  }

  private handleChange(increment: boolean, id: string) {
    const newNumber = increment ? this.numValue++ : this.numValue--

    if (this.maxNumber && this.numValue >= this.maxNumber) {
      document.querySelector(`#${id} ~ .${this.brand}-number-field-up`).classList.add(`${this.brand}-number-field-max`)
      return this.numValue = this.maxNumber
    } else if (!this.minNumber) {
      document.querySelector(`#${id} ~ .${this.brand}-number-field-up`).classList.remove(`${this.brand}-number-field-max`)
      this.emitValue(id)

      return newNumber
    }

    if (this.minNumber && this.numValue <= this.minNumber) {
      document.querySelector(`#${id} ~ .${this.brand}-number-field-down`).classList.add(`${this.brand}-number-field-min`)
      return this.numValue = this.minNumber
    } else {
      document.querySelector(`#${id} ~ .${this.brand}-number-field-down`).classList.remove(`${this.brand}-number-field-min`)
      this.emitValue(id)

      return newNumber
    }
  }

  render() {
    const handleWheel = (event: WheelEvent) => {
      if (event.deltaY > 0) {
        this.handleChange(false, this.currentInputId)
      } else {
        this.handleChange(true, this.currentInputId)
      }
    }

    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'ArrowDown') {
        this.handleChange(false, this.currentInputId)
      }

      if (event.key === 'ArrowUp') {
        this.handleChange(true, this.currentInputId)
      }
    }

    const icon = (change: boolean, iconName: 'up' | 'down') => {
      return (
        <ptz-icon
          onClick={() => this.handleChange(change, this.currentInputId)}
          class={`
            ${this.brand}-number-field-icon
            ${this.brand}-number-field-${iconName}
            ${this.brand}-number-field-${iconName}${this.kind && this.size ? `-${this.kind}-${this.size}` : ''}${this.supportLabel ? '-support-label' : ''}
          `}
          name={`petz-chevron-${iconName}`}
          color='neutral-dark'
          variant="line"
          size='3xs'
        ></ptz-icon>
      )
    }

    return (
      <Host>
        <ptz-input
          class={`${this.brand}-number-field`}
          value={`${this.numValue}`}
          onWheel={handleWheel}
          onKeyDown={handleKeyDown}
          state={this.inputState}
          spellcheck={false}
          inputId={this.currentInputId}
          supportLabel={this.supportLabel}
          label={this.label}
          requirementLabel={this.requirementLabel}
          required={this.required}
          placeholder=''
          brand={this.brand}
          iconInformation={this.iconInformation}
          kind={`${this.kind}`}
          size={`${this.size}`}
          type="number"
        >
          {icon(true, 'up')}
          {icon(false, 'down')}
        </ptz-input>
      </Host>
    );
  }
}
