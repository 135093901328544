import { Component, h, Host, Method, Prop, State } from '@stencil/core';
import { PTZInputTypes } from '../ptz-input/types/ptz-input.types';
import { PTZInputConfig } from '../ptz-input/types/ptz-input.enums';
import { PTZPasswordFieldTypes } from './types/ptz-password-field.types';
import { PTZPasswordFieldConfig } from './types/ptz-password-field.enums';
import { PTZBrandTypes } from '../ptz-brand/types/ptz-brand.types';
import { PTZBrandConfig } from '../ptz-brand/types/ptz-brand.enums';
import { currentId } from '../../utils/id';
import { PTZIconTypes } from '../ptz-icon/types/ptz-icon.types';
import { PTZIconConfig } from '../ptz-icon/types/ptz-icon.enums';

@Component({
  tag: 'ptz-password-field',
  styleUrl: 'ptz-password-field.scss',
  shadow: false
})

export class PTZPasswordField {
  /** Define o estilo da marca do Input */
  @Prop() brand?: PTZBrandTypes.Name = PTZBrandConfig.Name.Petz

  /** Define o estilo da marca do input */
  @Prop() iconInformation?: boolean = false

  /** Estado do input */
  @Prop() inputState?: PTZInputTypes.State

  /** Variante do input */
  @Prop() kind: PTZPasswordFieldTypes.Kind = PTZPasswordFieldConfig.Kind.Boxed

  /** Preenche label do input */
  @Prop() label?: string

  /** Preenche placeholder do input */
  @Prop() placeholder?: string

  /** Preenche required padrão do input */
  @Prop() required?: boolean = false

  /** Preenche label quando input for obrigatório do input */
  @Prop() requirementLabel?: PTZInputTypes.RequirementLabel

  /** Tamanho do input */
  @Prop() size: PTZPasswordFieldTypes.Size = PTZPasswordFieldConfig.Size.Large

  /** Estado para senha do input */
  @Prop() state?: PTZPasswordFieldTypes.State

  /** Mensagem de suporte do input */
  @Prop() supportLabel?: string

  /** Tipo de estilo do input */
  @Prop() type: PTZPasswordFieldTypes.Type = PTZPasswordFieldConfig.Type.Simple

  /** Atributo value padrão do input */
  @Prop() value: string

  /** Cor do ícone no Input */
  @Prop() iconColor?: PTZIconTypes.Color = PTZIconConfig.Color.NeutralDark;

  /** Preenche id padrão do input */
  @Prop() inputId?: string

  /** Preenche id para rules do input de senha */
  @Prop() rulesId?: string

  @State() visiblePassword: boolean = false

  private currentInputId = currentId(this.inputId)

  private currentRulesId = currentId(this.rulesId)

  private handleVisiblePassword() {
    this.visiblePassword = !this.visiblePassword
  }

  @Method()
  async setRules(obj: { rule: string, passed: boolean }[]) {
    const textFieldRules = document.getElementById(this.currentRulesId)
    textFieldRules.innerHTML = ''

    obj.forEach(item => {
      if (textFieldRules) {
        textFieldRules.innerHTML += `<li><ptz-icon name='petz-escape' variant='line' size='3xs' color="${item.passed ? 'sup-green-dark' : ''}"></ptz-icon><span>${item.rule}</span></li>`
      }
    })
  }

  private handleKeyDown(e: KeyboardEvent) {
    if (e.code === 'Enter') {
      this.visiblePassword = !this.visiblePassword
    }
  }

  render() {
    return (
      <Host>
        <ptz-input
          brand={this.brand}
          class='ptz-password-field'
          iconInformation={this.iconInformation}
          inputId={this.currentInputId}
          kind={this.kind}
          label={this.label}
          placeholder={this.placeholder}
          required={this.required}
          requirementLabel={this.requirementLabel}
          size={this.size}
          spellcheck={false}
          state={this.inputState}
          supportLabel={this.supportLabel}
          type={this.visiblePassword ? 'text' : 'password'}
          value={this.value}
        >
          <a tabindex="0" aria-label="Mostrar/Ocultar senha" onKeyDown={(e) => this.handleKeyDown(e)} class={`ptz-password-field-icon ${this.brand}-password-field-icon-${this.kind}-${this.size}${this.supportLabel ? '-support-label' : ''}`} onClick={() => this.handleVisiblePassword()}>
            <ptz-icon name={this.visiblePassword ? 'eye' : 'eye-slash'} color={this.state === PTZInputConfig.State.Error ? PTZIconConfig.Color.SupRedMid :  this.iconColor} variant="line" size='lg'></ptz-icon>
          </a>
        </ptz-input>

        {this.type === PTZPasswordFieldConfig.Type.Bar && <ul id={this.currentRulesId} class={`ptz-password-field-rules`}></ul>}
      </Host>
    );
  }
}
