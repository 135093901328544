ptz-card-price-section {
  min-height: 2.313rem;

  .ptz-card-price {
    margin: 0;
    display: block;
    font-weight: $petz-font-weight-bold;
    font-size: $petz-font-size-xs;
    line-height: $petz-font-lineheight-lg;
    color: $petz-color-neutral-darker-accent;

    &-older {
      margin-left: $petz-spacing-3xs;
      font-size: $petz-font-size-3xs;
      color: $petz-color-neutral-dark;

      &-line-through {
        text-decoration-line: line-through;
      }
    }

    &-installments {
      display: block;
      font-weight: $petz-font-weight-regular;
      font-size: $petz-font-size-3xs;
      color: $petz-color-neutral-dark;
      line-height: $petz-font-lineheight-lg;
    }

    &-showcase {
      display: flex;
      flex-direction: column;

      &-older {
        margin-left: 0;
        font-weight: $petz-font-weight-regular;
      }
    }
  }
}
