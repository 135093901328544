import { EnumToArray } from '../../../utils/enumToArray';
import { PTZBrandConfig, PTZBrandConfigList } from '../../ptz-brand/types/ptz-brand.enums';

enum Kind {
  Boxed = 'boxed',
  Lined = 'lined',
}

enum Size {
  Sm = 'sm',
  Mid = 'md',
  Large = 'lg',
  XLarge = 'xl',
}

export const PTZDropdownFieldConfigList = {
  Brand: PTZBrandConfigList.Name,
  Kind: EnumToArray([Kind]),
  Size: EnumToArray([Size]),
};

export const PTZDropdownFieldConfig = {
  Brand: PTZBrandConfig.Name,
  Kind,
  Size,
};
