import { EnumToArray } from '../../../utils/enumToArray';

enum Control {
  Normal = 'normal',
  Password = 'password',
  Number = 'number',
  Dropdown = 'dropdown',
}

export const PTZTextFieldConfigList = {
  Control: EnumToArray([Control]),
};

export const PTZTextFieldConfig = {
  Control,
};
