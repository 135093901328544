ptz-number-field {
  .ptz-number-field {
    input {
      position: relative;
      width: 260px;
      padding-right: 45px;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        appearance: none;
        margin: 0;
      }
    }

    &-icon {
      position: absolute;
      cursor: pointer;
      right: 15px;

      i {
        display: flex;
      }
    }

    &-min,
    &-max {
      cursor: not-allowed;
    }

    &-up {

      &-lined,
      &-boxed {

        &-sm {
          bottom: 19px;

          &-support-label {
            bottom: 38px;
          }
        }

        &-md {
          bottom: 19px;

          &-support-label {
            bottom: 42px;
          }
        }

        &-lg {
          bottom: 19px;

          &-support-label {
            bottom: 48px;
          }
        }

        &-xl {
          bottom: 19px;

          &-support-label {
            bottom: 60px;
          }
        }
      }
    }

    &-down {

      &-lined,
      &-boxed {

        &-sm {
          bottom: $petz-spacing-2xs;

          &-support-label {
            bottom: 27px;
          }
        }

        &-md {
          bottom: $petz-spacing-2xs;

          &-support-label {
            bottom: 31px;
          }
        }

        &-lg {
          bottom: $petz-spacing-2xs;

          &-support-label {
            bottom: 35px;
          }
        }

        &-xl {
          bottom: $petz-spacing-2xs;

          &-support-label {
            bottom: 45px;
          }
        }
      }
    }
  }

  .srs-number-field {
    input {
      position: relative;
      width: 260px;
      padding-right: 45px;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        appearance: none;
        margin: 0;
      }
    }

    &-icon {
      position: absolute;
      cursor: pointer;
      right: 15px;

      i {
        display: flex;
      }
    }

    &-min,
    &-max {
      cursor: not-allowed;
    }

    &-up {

      &-lined,
      &-boxed {

        &-sm {
          bottom: 19px;

          &-support-label {
            bottom: 38px;
          }
        }

        &-md {
          bottom: 19px;

          &-support-label {
            bottom: 42px;
          }
        }

        &-lg {
          bottom: 19px;

          &-support-label {
            bottom: 48px;
          }
        }

        &-xl {
          bottom: 19px;

          &-support-label {
            bottom: 60px;
          }
        }
      }
    }

    &-down {

      &-lined,
      &-boxed {

        &-sm {
          bottom: $seres-spacing-2xs;

          &-support-label {
            bottom: 27px;
          }
        }

        &-md {
          bottom: $seres-spacing-2xs;

          &-support-label {
            bottom: 31px;
          }
        }

        &-lg {
          bottom: $seres-spacing-2xs;

          &-support-label {
            bottom: 35px;
          }
        }

        &-xl {
          bottom: $seres-spacing-2xs;

          &-support-label {
            bottom: 45px;
          }
        }
      }
    }
  }
}