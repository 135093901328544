import { Component, Prop, Host, h, State, Listen, Watch } from '@stencil/core';
import PTZTokens from '@petz/ds-tokens/petz';
import { PTZCardBadgesCarouselTypes } from './types/ptz-card-badges-carousel.types';
import { PTZCardTypes } from '../ptz-card/types/ptz-card.types';
import { PTZSelectorConfig } from '../ptz-selector/types/ptz-selector.enums';

@Component({
  tag: `ptz-card-badges-carousel`,
  styleUrl: 'ptz-card-badges-carousel.scss',
  shadow: false
})

export class PTZCardBadgesCarousel {
  /** Lista de variação de produtos */
  @Prop() infoBadgesList: PTZCardTypes.InfoBadgeList | string;
  private _infoBadgesList: PTZCardTypes.InfoBadgeList;

  /** Tamanho do badge */
  @Prop() size: PTZCardBadgesCarouselTypes.Size;

  /** A versão da tela é mobile ou desktop */
  @State() isMobile: boolean = window.matchMedia(`(max-width:${PTZTokens.breakpointMd})`).matches;

  /** Informação sobre a media query */
  @State() matchMedia: MediaQueryList;

  /** Id da variação selecionada */
  @State() selectedId: string;

  private handleMatchMediaChange() {
    this.isMobile = this.matchMedia.matches;
  }

  @Listen('clickBadge')
  handleClickBadge(event) {
    if(event.detail.id != "combo") {
      this.selectedId = event.detail.id
    }
  }

  @Watch('infoBadgesList')
  infoBadgesListWatcher(newValue: PTZCardTypes.InfoBadgeList | string) {
    if (typeof newValue === 'string') {
      this._infoBadgesList = JSON.parse(newValue);
    }
    else {
      this._infoBadgesList = newValue;
    }
  }

  private getBadgeKind(badge) {
    if (!badge.availability) {
      return PTZSelectorConfig.Kind.Unavailable
    }
    if (!!badge.promotionalPrice) {
      return PTZSelectorConfig.Kind.Sale
    }

    return PTZSelectorConfig.Kind.Default
  }

  componentWillLoad() {
    this.infoBadgesListWatcher(this.infoBadgesList);
    this.matchMedia = window.matchMedia(`(max-width:${PTZTokens.breakpointMd})`);
    this.matchMedia.onchange = this.handleMatchMediaChange.bind(this);
  }

  componentDidLoad() {
    this._infoBadgesList && this._infoBadgesList.variations.find((badge) => {
      if (badge.isSelected) {
        this.selectedId = badge.id
      }
      return;
    })
  }

  disconnectedCallback() {
    this.matchMedia.onchange = null;
  }

  render() {
    return (
      <Host>
        <div class={"ptz-card-badges-carousel"} onClick={(e) => e.preventDefault()}>
          {this.isMobile && <div class="empty-box"></div>}
          <ptz-carousel hidden-buttons="true" slideSpacing={'none'} scrollMove={'half'}>
            {this._infoBadgesList && this._infoBadgesList.hasComboVariation ? (
            <ptz-selector
              badge-id="combo"
              size={this.size}
              text={'Combo'}
              selected={false}
              description={'Combo'}
              view={this.isMobile}
              kind={"combo"}
            />
                ) : ''}
            {this._infoBadgesList && this._infoBadgesList.variations.map((badge) => (
              <ptz-selector
                key={badge.id}
                badge-id={badge.id}
                badge-sku={badge.sku}
                size={this.size}
                text={badge.name}
                description={badge.description}
                view={this.isMobile}
                kind={this.getBadgeKind(badge)}
                selected={this.isMobile ? false : this.selectedId === badge.id} />
              ))}
              {this.isMobile ? '' : <ptz-icon
                slot='arrow-left'
                name="petz-chevron-left"
                size="md"
                variant="line"
                color="primary-brand"
                brand="ptz"></ptz-icon>}
              {this.isMobile ? '' : <ptz-icon
                slot='arrow-right'
                name="petz-chevron-right"
                size="md"
                variant="line"
                color="primary-brand"
                brand="ptz"></ptz-icon>}
          </ptz-carousel>
        </div>
      </Host>
    );
  }
}
