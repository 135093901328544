import { Component, h, Host, Prop, State, Event, EventEmitter, Watch } from '@stencil/core';
import { PTZCardConfig } from './types/ptz-card.enums';
import { PTZCardTypes } from './types/ptz-card.types';
import PTZTokens from '@petz/ds-tokens/petz';
import { PTZBadgeConfig } from '../ptz-badge/types/ptz-badge.enums';
import { PTZSelectorConfig } from '../ptz-selector/types/ptz-selector.enums';

@Component({
  tag: 'ptz-card',
  styleUrl: 'ptz-card.scss',
  shadow: false,
})
export class PTZCard {
  /** Atributo de click */
  @Prop({ mutable: true }) clicked: boolean = false;

  /** Variedade do Card */
  @Prop() kind: PTZCardTypes.Kind;

  /** Texto do Card */
  @Prop() label: string;

  /** Indica se o produto sem variação vai ter ou não o espaço em branco no mobile */
  @Prop() withSpace?: boolean = false;

  /** Indica se o produto está indisponível */
  @Prop() unavailable?: boolean = false;

  /** Texto de mais opções do Card */
  @Prop() productVariationsText?: string;

  /** Mostrar ou não botão no Card */
  @Prop() showButton?: boolean = true;

  /** Ícone de adicionar à sacola */
  @Prop() showBuyIcon?: boolean;

  /** Ícone de coração do Card */
  @Prop() showHeartIcon?: boolean;

  /** Mostrar o preço do Card */
  @Prop() showPrice?: boolean;

  /** SubTexto do Card */
  @Prop() supportLabel: string;

  /** Define a posição do card */
  @Prop() orientation?: PTZCardTypes.Orientation = PTZCardConfig.Orientation.Vertical;

  /** Define o tamanho do card */
  @Prop() size?: PTZCardTypes.Size = PTZCardConfig.Size.Large;

  /** Define se o card terá borda */
  @Prop() outlined?: boolean = true;

  /** Define o estado em que o card vai estar */
  @Prop() state?: PTZCardTypes.State = PTZCardConfig.State.Normal;

  /** Define se o card vai ter animação de hover */
  @Prop() animated?: boolean = false;

  /** Lista de variação de produtos para o card tipo showcase */
  @Prop() infoBadgesList?: PTZCardTypes.InfoBadgeList | string;
  private _infoBadgesList: PTZCardTypes.InfoBadgeList;

  /** Informação sobre a media query */
  @State() matchMedia: MediaQueryList;

  /** A versão da tela é mobile ou desktop */
  @State() isMobile: boolean = window.matchMedia(`(max-width:${PTZTokens.breakpointMd})`).matches;

  /** Evento que dispara no click do icone de coração */
  @Event({
    eventName: 'clickHeartIcon',
    composed: true,
  })
  clickHeartIcon: EventEmitter;

  @Watch('infoBadgesList')
  infoBadgesListWatcher(newValue: PTZCardTypes.InfoBadgeList | string) {
    if (typeof newValue === 'string') {
      this._infoBadgesList = JSON.parse(newValue);
    } else {
      this._infoBadgesList = newValue;
    }
  }

  private handleMatchMediaChange() {
    this.isMobile = this.matchMedia.matches;
  }

  componentWillLoad() {
    this.infoBadgesListWatcher(this.infoBadgesList);
    this.matchMedia = window.matchMedia(`(max-width:${PTZTokens.breakpointMd})`);
    this.matchMedia.onchange = this.handleMatchMediaChange.bind(this);
  }

  disconnectedCallback() {
    this.matchMedia.onchange = null;
  }

  private handleClickHeartButton(e: MouseEvent) {
    e.preventDefault();
    this.clicked = !this.clicked;
    this.clickHeartIcon.emit();
  }

  render() {
    const offerImgClass = this.kind === PTZCardConfig.Kind.Offer ? `ptz-card-offer-image` : '';
    const offerBtnClass = this.kind === PTZCardConfig.Kind.Offer ? `ptz-card-offer-${this.state}` : '';
    const labelClass = this.kind === PTZCardConfig.Kind.Offer ? 'ptz-card-label' : 'ptz-card-label-left';

    const unavailableBadgeSize = () => {
      if (this.isMobile && this.size === PTZCardConfig.Size.Medium) return PTZBadgeConfig.Size.Small;
      if (this.isMobile && this.size === PTZCardConfig.Size.Large) return PTZBadgeConfig.Size.Medium;
      if (this.size === PTZCardConfig.Size.Medium) return PTZBadgeConfig.Size.Medium;
      return PTZBadgeConfig.Size.Large;
    };
    const availableBadgeSize = () => {
      if (this.isMobile && this.size === PTZCardConfig.Size.Medium) return PTZSelectorConfig.Size.SizeSm;
      return PTZSelectorConfig.Size.SizeMd;
    };

    const renderShowHeartIcon = this.showHeartIcon ? (
      <button class="ptz-card-heart-icon-button" aria-label="Clique para favoritar" onClick={e => this.handleClickHeartButton(e)}>
        <ptz-icon
          size={this.orientation === 'vertical' ? 'md' : 'lg'}
          class="ptz-card-heart-icon"
          name={this.clicked ? 'petz-favorite-solid' : 'heart'}
          variant={this.clicked ? 'solid' : 'line'}
          color={this.clicked ? 'sup-red-dark' : 'neutral-mid'}
        />
      </button>
    ) : null;

    const renderProductVariationText =
      this.kind !== PTZCardConfig.Kind.Showcase && this.productVariationsText ? <span class="ptz-card-product-variations-text">{this.productVariationsText}</span> : null;

    const renderVariantionBadge = () => {
      if (this.kind === PTZCardConfig.Kind.Showcase) {
        if (this._infoBadgesList) {
          return <ptz-card-badges-carousel infoBadgesList={this._infoBadgesList} size={availableBadgeSize()} />;
        }

        if (this.productVariationsText) {
          return (
            <ptz-selector
              readOnly
              view={this.isMobile}
              kind={'default'}
              text={this.productVariationsText}
              description={this.productVariationsText}
              selected={false}
              size={availableBadgeSize()}
            />
          );
        }
      }

      return null;
    };

    const renderSuportLabel = this.supportLabel ? (
      <p class="ptz-card-support-label">
        <slot name="card-icon" />
        {this.supportLabel}
      </p>
    ) : null;

    const renderUnavailableBadge = () => (
      <ptz-badge class={`ptz-card-unavailable-badge ptz-card-unavailable-badge-${this.size}`} size={unavailableBadgeSize()} color="neutral-light" variant="solid">
        Produto indisponível
      </ptz-badge>
    );

    const classesPtzCard = {
      'ptz-card': true,
      [`ptz-card-${this.kind}`]: true,
      'ptz-card-outlined': this.outlined && this.kind !== PTZCardConfig.Kind.Showcase,
      [`ptz-card-showcase-${this.orientation}`]: this.kind === PTZCardConfig.Kind.Showcase,
      [`ptz-card-showcase-${this.orientation}-${this.size}`]: this.kind === PTZCardConfig.Kind.Showcase,
      [`ptz-card-product-${this.orientation}`]: this.kind === PTZCardConfig.Kind.Product,
      [`ptz-card-product-${this.orientation}-${this.size}`]: this.kind === PTZCardConfig.Kind.Product,
      'ptz-card-showcase-with-space': this.kind === PTZCardConfig.Kind.Showcase && this.isMobile && this.withSpace,
      'ptz-card-showcase-animated': this.kind === PTZCardConfig.Kind.Showcase && this.animated && (this._infoBadgesList || this.productVariationsText) && !this.isMobile
    }

    return (
      <Host>
        <div title={this.kind === PTZCardConfig.Kind.Showcase ? this.label : ""} class={classesPtzCard}>
          <div class={`ptz-card-portrayal ptz-card-portrayal-${this.size} ${offerImgClass}`}>
            <slot name="card-tag" />
            <slot name="card-image" />
            <slot name="card-add-button" />
            {renderShowHeartIcon}
            {renderProductVariationText}
          </div>
          <div class={`ptz-card-content ${offerBtnClass}`}>
            {this.unavailable && this.isMobile ? renderUnavailableBadge() : renderVariantionBadge()}
            <p class={labelClass}>{this.label}</p>
            {this.unavailable && !this.isMobile ? (
              renderUnavailableBadge()
            ) : (
              <div class={`ptz-card-content-wrapper`}>
                <slot name="card-price" />
                <slot name="card-subscription" />
                <slot name="card-button" />
                {renderSuportLabel}
              </div>
            )}
          </div>
        </div>
      </Host>
    );
  }
}
