ptz-card-badges-carousel {
  width: calc(100% + $petz-spacing-2xs);

  .ptz-card-badges-carousel {
    ptz-carousel {
      .ptz-carousel-slider {
        position: relative;
        left: -$petz-spacing-2xs;
        top: -$petz-spacing-3xs;
      }

      .ptz-carousel-arrow-left {
        left: -0.7rem;
        padding-left: $petz-spacing-2xs;
      }

      .ptz-carousel-arrow-right {
        right: 0.3rem;
      }

      .ptz-carousel-arrow-left, .ptz-carousel-arrow-right {
        z-index: 2;
        height: 100%;
        top: calc(50% - $petz-spacing-3xs);
        background-color: $petz-color-neutral-white;
      }
    }
  }
}

@media screen and (max-width: $petz-breakpoint-md) {
  ptz-card-badges-carousel {
    .ptz-card-badges-carousel {
      position: relative;

      ptz-carousel {
        .ptz-carousel-slider {
          padding-top: $petz-spacing-sm;
        }
      }

      .empty-box {
        display: block;
        width: $petz-spacing-2xs;
        position: absolute;
        z-index: 1;
        bottom: 0;
        top: -$petz-spacing-2xs;
        left: -$petz-spacing-2xs;
        background-color: $petz-color-neutral-white;
      }
    }
  }
}
